import { DateTime } from 'luxon';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ProfessorService } from "@services/dashboard/professor/professor.service";
import { RelatorioCadastroService } from '@services/relatorios/relatorio-cadastros/relatorio-cadastros.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
 import * as XLSX from 'xlsx';
 import { saveAs } from 'file-saver';
import { addDays, isValid } from 'date-fns';

 
@Component({
    selector: 'app-relatorio-ranking-avaliadores',
    templateUrl: './relatorio-ranking-avaliadores.html',
    styleUrls: ['./relatorio-ranking-avaliadores.scss']
})
export class RelatorioRankingAvaliadores implements OnInit {
    public relatorioCheckupList: any;
    public formCadastro: FormGroup;
    public listaAvaliadores: Array<any> = [];
    public loading = false
    public nenhumRegistro = true

    public listaUF = [
        { id: 1, nome: 'AC' },
        { id: 2, nome: 'AL' },
        { id: 3, nome: 'AP' },
        { id: 4, nome: 'AM' },
        { id: 5, nome: 'BA' },
        { id: 6, nome: 'CE' },
        { id: 7, nome: 'DF' },
        { id: 8, nome: 'ES' },
        { id: 9, nome: 'GO' },
        { id: 10, nome: 'MA' },
        { id: 11, nome: 'MT' },
        { id: 12, nome: 'MS' },
        { id: 13, nome: 'MG' },
        { id: 14, nome: 'PA' },
        { id: 15, nome: 'PB' },
        { id: 16, nome: 'PR' },
        { id: 17, nome: 'PE' },
        { id: 18, nome: 'PI' },
        { id: 19, nome: 'RJ' },
        { id: 20, nome: 'RN' },
        { id: 21, nome: 'RS' },
        { id: 22, nome: 'RO' },
        { id: 23, nome: 'RR' },
        { id: 24, nome: 'SC' },
        { id: 25, nome: 'SP' },
        { id: 26, nome: 'SE' },
        { id: 27, nome: 'TO' }
    ]

    constructor(private relatorioCadastroService: RelatorioCadastroService, private professorService: ProfessorService) {
    }

    ngOnInit() {
        this.formCadastro = new FormGroup({
            AvaliadorID: new FormControl(0, []),
            DataInicio: new FormControl('2024-01-01', []),
            UF: new FormControl('', []),
            NumeroMinimoDeCheckups: new FormControl(0, []),
            DataFim: new FormControl('2024-12-01', []),
        });

        this.atualizarListaAvaliadores()
    }

    atualizarListagemRelatorio() {
        const classeContexto = this
        this.loading = true
        if (this.formCadastro.value.NumeroMinimoDeCheckups == '') {
            this.formCadastro.value.NumeroMinimoDeCheckups = 0
        }
        this.relatorioCadastroService.postRelatorioRanking(this.formCadastro.value).subscribe({
            next(response) {
                classeContexto.relatorioCheckupList = response as Array<any>
                // @ts-ignore
                classeContexto.nenhumRegistro = classeContexto.relatorioCheckupList.Resultados.length === 0
                classeContexto.loading = false
                
                setTimeout(() => {
                    classeContexto.scrollToElementById('listaPacientes')
                }, 100)
            }, error() {
                classeContexto.loading = false
            }
        })
    }

    formatDate(date) {
        return DateTime.fromISO(date).toFormat('dd/MM/yyyy');
      }

    atualizarListaAvaliadores() {
        const classeContexto = this
        this.professorService.getObterListaAvaliadores().subscribe({
            next(response) {
                classeContexto.listaAvaliadores = response as Array<any>
            }, error() {
            }
        })
    }

    baixarPDF() {
        const doc = new jsPDF();
    
        // Adicionando a logo no canto superior direito
        const logo = new Image();
        logo.src = 'assets/img/LogoIconTextPlataformaSafe.png'; // Caminho para a imagem
        logo.onload = () => {
          
    
            const avaliadorSelecionado = this.listaAvaliadores.find(avaliador => avaliador.ID === this.formCadastro.value.AvaliadorID)?.Nome || 'Todos';
            const periodoDeRaw = this.formCadastro?.value?.PeriodoDe;
            const periodoAteRaw = this.formCadastro?.value?.PeriodoAte;
    
            const periodoDe = isValid(new Date(periodoDeRaw))
                ? this.dateToDDMMYYYY(addDays(new Date(periodoDeRaw), 1))
                : 'Não especificado';
    
            const periodoAte = isValid(new Date(periodoAteRaw))
                ? this.dateToDDMMYYYY(addDays(new Date(periodoAteRaw), 1))
                : 'Não especificado';
    
             // Adicionando cabeçalho
            doc.addImage(logo, 'PNG', 150, 10, 50, 40); // Adiciona a imagem (x: 150, y: 10, largura: 50, altura: 40)
    
            // Adicionando cabeçalho
            doc.setFontSize(14);
            doc.text('Relatório de Evolução', 14, 20);
    
            // Adicionando informações do totalizador
            doc.setFontSize(12);
            doc.text(`Avaliador: ${avaliadorSelecionado}`, 14, 30);
            doc.text(`Total de Anamneses: ${this.relatorioCheckupList.TotalAnamneses}`, 14, 40);
            doc.text(`Total de Avaliações: ${this.relatorioCheckupList.TotalAvaliacoes}`, 14, 50);
            doc.text(`Total de Pacientes: ${this.relatorioCheckupList.TotalPacientes}`, 14, 60);
            doc.text(`De ${periodoDe} até ${periodoAte}`, 14, 70);
     
            // Adicionando tabela
            (doc as any).autoTable({
                head: [['#', 'Avaliador', 'UF', 'Anamneses', 'Av. Físicas', 'Pacientes']],
                body: this.relatorioCheckupList.Resultados?.map((relatorio, index) => [
                    index + 1,
                    relatorio.NomeAvaliador,
                    relatorio.UF,
                    relatorio.QtdeAnamneses,
                    relatorio.QtdeAvaliacoes,
                    relatorio.QtdePacientes,
                ]),
                startY: 80,
            });
    
            // Baixar o PDF
            doc.save(`relatorio_ranking_avaliadores_${new Date().toISOString().slice(0, 10)}.pdf`);
        };
    }
    
    
    
   
    baixarExcel() {
        // Prepare os dados para exportação
        const data = this.relatorioCheckupList.Resultados?.map((relatorio, index) => ({
            '#': index + 1,
            'Avaliador': relatorio.NomeAvaliador,
            'UF': relatorio.UF,
            'Anamneses': relatorio.QtdeAnamneses,
            'Av. Físicas': relatorio.QtdeAvaliacoes,
            'Pacientes': relatorio.QtdePacientes,
            
        }));

        // Crie uma nova planilha
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);

        // Crie um novo livro de trabalho
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Relatório Ranking Avaliadores': worksheet },
            SheetNames: ['Relatório Ranking Avaliadores']
        };

        // Converta o livro de trabalho para um arquivo binário
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

        // Baixar o arquivo
        this.saveAsExcelFile(excelBuffer, `relatorio_ranking_avaliadores_${new Date().toISOString().slice(0, 10)}`);
    }
    

    private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private EXCEL_EXTENSION = '.xlsx';

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
        saveAs(data, fileName + this.EXCEL_EXTENSION);
    }
    

    baixarLaudo(id: number) {
        window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${id}`, "_blank")
      }

    scrollToElementById(id: string) {
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }

    dateToDDMMYYYY(date: Date) {
        if (!date) return date
        date = new Date(date)
        return date.toLocaleDateString()
    }

    getGenero(idGenero: number) {
        switch (idGenero) {
            case 1:
                return 'Masculino'
            case 2:
                return 'Feminino'
            default:
                return ''
        }
    }

}
