import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { AppService } from '@services/app.service';
import { CheckupClienteService } from '@services/dashboard/checkup-cliente/checkup-cliente.service';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-usuarios-onlines',
  templateUrl: './usuarios-onlines.component.html',
  styleUrls: ['./usuarios-onlines.component.scss']
})
export class UsuariosOnlinesComponent {
  public loadingListaClientes: boolean = false;
  public listaClientes: any[] = []


  

  constructor(private appService: AppService, private professorService: ProfessorService, private toastr: ToastrService,) {
  }


  ngOnInit(): void {
    const classeContexto = this
    this.loadingListaClientes = true
    this.professorService.getListarUsuariosOnlines().subscribe((res: any) => {
      this.listaClientes = res
      this.loadingListaClientes = false
      console.log(this.listaClientes,'listaClientes')
    }
    ), (error) => {
      this.toastr.error('Erro ao carregar lista de usuários online')
      this.loadingListaClientes = false
    }
  }

  formatTemp(temp) {
    //recebe 00:03:09.5904659 e retorna 03:09
    return DateTime.fromISO(temp).toFormat('mm')
  }


}
