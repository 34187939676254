<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">

    <div class="container-fluid bg-transparent">
 

        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5 text-amarelo-plataforma-safe">
                    Anamneses
                </h5>
                <img alt="plat-safe-logo" height="122px" src="assets/img/logoSafeTextoBranco.png" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>


        <div class="container-tabela">
            <h6 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe mb-3">
                Filtrar Anamneses
            </h6>

            <div class="col-md-12 mt-6 p-4">
                <div class="row">
                    <div class="col-sm-12 col-md-2 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Paciente</span>
                        <input [(ngModel)]="dataPesquisa.Paciente" class="form-input-box form-control" id="Paciente"
                               name="Paciente"
                               type="input">
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Grupo</span>

                        <input [(ngModel)]="dataPesquisa.Grupo" class="form-input-box form-control" id="Grupo"
                               name="Grupo"
                               type="input">
                    </div>

                    <div class="col-md-4 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Avaliador</span>

                        <input [(ngModel)]="dataPesquisa.Avaliador" class="form-input-box form-control" id="Avaliador"
                               name="Avaliador"
                               type="input">
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Cupom</span>

                        <input [(ngModel)]="dataPesquisa.Cupom" class="form-input-box form-control" id="Cupom"
                               name="Cupom"
                               type="input">
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Evento</span>

                        <input [(ngModel)]="dataPesquisa.Evento" class="form-input-box form-control" id="Evento"
                               name="Evento"
                               type="input">
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Período de:</span>
                        <input [(ngModel)]="dataPesquisa.PeriodoDe" class="form-input-box form-control" id="PeriodoDe"
                               name="PeriodoDe"
                               type="date">
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Período até:</span>

                        <input [(ngModel)]="dataPesquisa.PeriodoAte" class="form-input-box form-control" id="PeriodoAte"
                               name="PeriodoAte"
                               type="date">
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Z:</span>
                        <input [(ngModel)]="dataPesquisa.Z" class="form-input-box form-control" id="Z"
                               name="Z"
                               type="input">
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Status:</span>
                        <select [(ngModel)]="dataPesquisa.Status" class="form-control form-input-select" id="Status"
                                placeholder="Selecione">
                            <option *ngFor="let option of opcoesStatus" [ngValue]="option.value">
                                {{ option.descricao }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Preenchida:</span>
                        <select [(ngModel)]="dataPesquisa.Preenchida" class="form-control form-input-select"
                                id="Preenchida"
                                placeholder="Selecione">
                            <option *ngFor="let option of opcoesPreenchida" [ngValue]="option.value">
                                {{ option.descricao }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-2 col-6 mt-2">
                        <span class="text-azul-claro-plataforma-safe">Avaliada:</span>

                        <select [(ngModel)]="dataPesquisa.Avaliada" class="form-control form-input-select" id="Avaliada"
                                placeholder="Selecione">
                            <option *ngFor="let option of opcoesPreenchida" [ngValue]="option.value">
                                {{ option.descricao }}
                            </option>
                        </select>
                    </div>

                    <div class="col-md-9 col-sm-9"></div>

                    <div class="col-md-3 col-sm-3 mt-2 d-flex align-items-center justify-content-center ">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4" type="button"
                                (click)="getListaAnamneses()"
                                [disabled]="loadingListaAnamneses"
                                >
                            {{
                            loadingListaAnamneses ? 'Carregando...' : 'Pesquisar'
                            }}
                        </button>
                    </div>
                </div>
            </div>

        </div>

        <div class="mt-5">
            <h6 class="titulo-usuarios text-cinza-claro-plataforma-safe">
                Ações
            </h6>
            <span
                class="text-help text-cinza-claro-plataforma-safe">Para ativar alguma ação, selecione algum paciente.</span>
            <div class="col-12 mt-6">
                <div class="row">
                    <div class="col-12 col-md-3 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4"
                                type="button" (click)="handleDevolverAnamnese()" [disabled]="!anamnesesSelecionadas.length || loadingDevolverAnamnese
                        || anamnesesSelecionadas.length > 1"
                        >
                            Devolver Anamnese
                        </button>
                    </div>
                    <div class="col-12 col-md-3 mt-2">
                        <button
                            class="button-usuarios btn btn-secondary btn-block mt-4"
                            type="button"
                            [disabled]="!anamnesesSelecionadas.length || loadingDevolverAnamnese
                        || anamnesesSelecionadas.length > 1 || avaliarLoading"
                            (click)="onAvaliar()"
                        >
                             {{ avaliarLoading ? 'Aguarde...' : 'Avaliar' }}
                        </button>
                    </div>
                    <div class="col-12 col-md-3 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4"   type="button" 
                        [disabled]="!anamnesesSelecionadas.length || loadingDevolverAnamnese
                        || anamnesesSelecionadas.length > 1 || avaliarLoading"
                            (click)="handleVisualizarPacienteAnamnese()">
                            Visualizar
                        </button>
                    </div>

                    <div class="col-12 col-md-3 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4"
                                type="button" (click)="handleLaudo('completo')" [disabled]="!anamnesesSelecionadas.length
                        || anamnesesSelecionadas.length > 1">
                            Laudo Completo
                        </button>
                    </div>
                    <div class="col-12 col-md-3 mt-2">
                        <button class="button-usuarios btn btn-secondary btn-block mt-4"
                                type="button" (click)="handleLaudo('incompleto')" [disabled]="!anamnesesSelecionadas.length
                        || anamnesesSelecionadas.length > 1">
                            Laudo Completo sem dados pessoais
                        </button>
                    </div>
                </div>

            </div>


        </div>


        <div class="container-tabela mt-5" style="max-height: 600px; overflow-y: auto;">

            <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                Anamneses Cadastradas: {{ listaAnamneses?.length || 0 }}
            </h2>

            <div class="col-12 col-md-3 mt-2" *ngIf="listaAnamneses?.length > 0 && !loadingListaAnamneses">
                <button class="btn btn-secondary btn-block mt-4" 
                style="margin-left: 5px;"
                type="button" 
                (click)="baixarPDF()"
                >
                    PDF Pacientes sem Anamnese
                </button>
            </div>

            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="th-tabela text-azul-claro-plataforma-safe">
                        <input (change)="selectAll($event)" class="form-checkbox-input" id="selectAll"
                               type="checkbox">
                    </th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Protocolo</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Paciente</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Profissão</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Avaliador</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Cadastro</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Preenchida</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Início</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Envio</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Tempo de preenchimento</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Avaliada</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Avaliação</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Status</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="nenhumaAnamneseEncontrada && !loadingListaAnamneses">
                    <td class="text-center" colspan="14">Nenhum Checkup encontrado, por favor insira um filtro.</td>
                </tr>
                <ng-container *ngIf="!nenhumaAnamneseEncontrada && !loadingListaAnamneses">
                    <tr *ngFor="let anamnese of listaAnamneses">
                        <td><input (change)="selecionarPaciente(anamnese)"
                                   [value]="anamnese"
                                   class="form-checkbox-input"
                                   type="checkbox"></td>
                        <td>{{ anamnese.Protocolo }}</td>
                        <td>
                            <a class="text-azul-plataforma-safe"
                            style="cursor: pointer;"
                               (click)="handleVisualizarCadastro(anamnese.PacienteID)">
                            {{ anamnese.Paciente }}
                            </a>
                        </td>
                        <td>{{ anamnese.Ocupacao }}</td>
                        <td>{{ anamnese.Professor }}</td>
                        <td>{{ anamnese.Cadastro ? formatDate(anamnese.Cadastro) : 'Sem informação' }}</td>
                        <td>{{ anamnese.Preenchida === false ? 'Não' : 'Sim' }}</td>
                        <td>{{ anamnese.DataInicio ? formatDate(anamnese.DataInicio) : 'Sem informação' }}</td>
                        <td>{{ anamnese.DataEnvio ? formatDate(anamnese.DataEnvio) : 'Sem informação' }}</td>
                        <td>{{ anamnese.TempoPreenchimento }}</td>
                        <td>{{ anamnese.Avaliada === false ? 'Não' : 'Sim' }}</td>
                        <td>{{ anamnese.DataAvaliacao ? formatDate(anamnese.DataAvaliacao) : 'Sem informação' }}</td>
                        <td>{{ anamnese.Status === 1 ? 'Em branco' : anamnese.Status === 2 ? 'Incompleto' : anamnese.Status === 3 ? 'Completo' : 'Sem informação' }}</td>
                        <td>
                            <button class="button-usuarios btn btn-secondary btn-block mt-4"
                                    style="width: 100px;"
                                    (click)="handleVisualizarAnamnese(anamnese.IdentificadorHash)"
                                    type="button">
                                Laudo
                            </button>
                        </td>
                    </tr>
                </ng-container>
                <tr *ngIf="loadingListaAnamneses">
                    <td class="text-center align-middle" colspan="14">
                        <div class="spinner-border text-azul-plataforma-safe" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div id="listaPacientes" #target></div>


    </div>
</div>
