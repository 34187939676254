import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from "@angular/forms";
import { ProfessorService } from "@services/dashboard/professor/professor.service";
import { RelatorioCadastroService } from '@services/relatorios/relatorio-cadastros/relatorio-cadastros.service';
import { jsPDF } from 'jspdf';
import 'jspdf-autotable';
 import * as XLSX from 'xlsx';
 import { saveAs } from 'file-saver';

 
@Component({
    selector: 'app-relatorioCheckupsEvolucaoComponent',
    templateUrl: './relatorio-checkups-evolucao.component.html',
    styleUrls: ['./relatorio-checkups-evolucao.component.scss']
})
export class CheckupsEvolucaoComponent implements OnInit {
    public relatorioCheckupList: any;
    public formCadastro: FormGroup;
    public listaAvaliadores: Array<any> = [];
    public loading = false
    public nenhumRegistro = true

    constructor(private relatorioCadastroService: RelatorioCadastroService, private professorService: ProfessorService) {
    }

    ngOnInit() {
        this.formCadastro = new FormGroup({
            AvaliadorID: new FormControl(0, []),
            DataInicio: new FormControl('', []),
            DataFim: new FormControl('', []),
        });

        this.atualizarListaAvaliadores()
    }

    atualizarListagemRelatorio() {
        const classeContexto = this
        this.loading = true
        this.relatorioCadastroService.getRelatorioEvolucao(this.formCadastro.value).subscribe({
            next(response) {
                classeContexto.relatorioCheckupList = response as Array<any>
                // @ts-ignore
                classeContexto.nenhumRegistro = classeContexto.relatorioCheckupList.Lista.length === 0
                classeContexto.loading = false
                
                setTimeout(() => {
                    classeContexto.scrollToElementById('listaPacientes')
                }, 100)
            }, error() {
                classeContexto.loading = false
            }
        })
    }

    atualizarListaAvaliadores() {
        const classeContexto = this
        this.professorService.getObterListaAvaliadores().subscribe({
            next(response) {
                classeContexto.listaAvaliadores = response as Array<any>
            }, error() {
            }
        })
    }

    baixarPDF() {
        const doc = new jsPDF();
    
        // Adicionando a logo no canto superior direito
        const logo = new Image();
        logo.src = 'assets/img/LogoIconTextPlataformaSafe.png'; // Caminho para a imagem
        logo.onload = () => {
            doc.addImage(logo, 'PNG', 150, 10, 50, 40); // Adiciona a imagem (x: 150, y: 10, largura: 50, altura: 40)
    
            // Adicionando cabeçalho
            doc.setFontSize(14);
            doc.text('Relatório de Evolução', 14, 20);
    
            // Adicionando informações do totalizador
            doc.setFontSize(12);
            doc.text(`Total de Cadastros: ${this.relatorioCheckupList.TotalizadorCadastro}`, 14, 30);
            doc.text(`Total de CHECK-UPS: ${this.relatorioCheckupList.TotalizadorCheckup}`, 14, 40);
            doc.text(`CHECK-UPS feitos novamente: ${this.relatorioCheckupList.TotalizadorCheckupFeitoNovamente}`, 14, 50);
    
            // Adicionando tabela de valores Z
            const body = this.relatorioCheckupList.Lista.map((relatorio, index) => {
                const zValues = relatorio.ListaZ.map(z => z.Quantidade); // Extrai todas as quantidades de Z
    
                // Preenche com zeros se houver menos de 5 valores Z
                while (zValues.length < 5) {
                    zValues.push(0);
                }
    
                return [
                    index + 1,
                    relatorio.NomeAvaliador,
                    relatorio.NovosClientes,
                    relatorio.NovosCheckups,
                    relatorio.CheckupsFeitoNovamente,
                    ...zValues // Espalha os valores Z na linha
                ];
            });
    
            (doc as any).autoTable({
                head: [['#', 'Avaliador', 'Novos Clientes', 'Novos CHECK-UPS', 'CHECK-UPS feitos novamente', 'Z1', 'Z2', 'Z3', 'Z4', 'Z5']],
                body: body,
                startY: 60,
            });
    
            // Baixar o PDF
            doc.save(`relatorio_evolucao_${new Date().toISOString().slice(0, 10)}.pdf`);
        };
    }
    
    
    
    baixarExcel() {
        // Prepare os dados para exportação
        const data = this.relatorioCheckupList.Lista.map((relatorio, index) => {
            const zValues = relatorio.ListaZ.map(z => z.Quantidade); // Extrai todas as quantidades de Z
    
            // Preenche com zeros se houver menos de 5 valores Z
            while (zValues.length < 5) {
                zValues.push(0);
            }
    
            return {
                '#': index + 1,
                'Avaliador': relatorio.NomeAvaliador,
                'Novos Clientes': relatorio.NovosClientes,
                'Novos CHECK-UPS': relatorio.NovosCheckups,
                'CHECK-UPS Feitos Novamente': relatorio.CheckupsFeitoNovamente,
                'Z1': zValues[0],
                'Z2': zValues[1],
                'Z3': zValues[2],
                'Z4': zValues[3],
                'Z5': zValues[4]
            };
        });
    
        // Crie uma nova planilha
        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    
        // Crie um novo livro de trabalho
        const workbook: XLSX.WorkBook = {
            Sheets: { 'Relatório de Evolução': worksheet },
            SheetNames: ['Relatório de Evolução']
        };
    
        // Converta o livro de trabalho para um arquivo binário
        const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    
        // Baixar o arquivo
        this.saveAsExcelFile(excelBuffer, `relatorio_evolucao_${new Date().toISOString().slice(0, 10)}`);
    }
    

    private EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    private EXCEL_EXTENSION = '.xlsx';

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], { type: this.EXCEL_TYPE });
        saveAs(data, fileName + this.EXCEL_EXTENSION);
    }
    

    baixarLaudo(id: number) {
        window.open(`https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${id}`, "_blank")
      }

    scrollToElementById(id: string) {
        const element = document.getElementById(id)
        element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }

    dateToDDMMYYYY(date: Date) {
        if (!date) return date
        date = new Date(date)
        return date.toLocaleDateString()
    }

    getGenero(idGenero: number) {
        switch (idGenero) {
            case 1:
                return 'Masculino'
            case 2:
                return 'Feminino'
            default:
                return ''
        }
    }

}
