import { Component, OnInit } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import Chart from 'chart.js'

@Component({
  selector: 'app-consultar-niveis-pacientes-por-genero',
  templateUrl: './consultar-niveis-pacientes-por-genero.component.html',
  styleUrls: ['./consultar-niveis-pacientes-por-genero.component.scss']
})
export class ConsultarNiveisPacientesPorGeneroComponent implements OnInit {
  public chart: any;
  public loadingGetDadosConsultaZporGenero: boolean = false

  constructor(
    private professorService: ProfessorService
  ) {

  }

  // 0084B9
  // E6A4B4

  ngOnInit(): void {
    const classeContexto = this
    this.loadingGetDadosConsultaZporGenero = true
    this.professorService.getConsultaZPorGenero().subscribe({
      next(response) {
        classeContexto.loadingGetDadosConsultaZporGenero = false;
        classeContexto.createChart(response)
      }
    })
  }
  createChart(data: any = undefined) {
    const backgroundColor = [
      '#0084B9',
      'rgb(255, 99, 132)',
    ]

    const labels = [
      'Homens',
      'Mulheres',
    ]

    const z1 = { label: 'Z1', data: [0,0], backgroundColor: '#B91520' }
    const z2 = { label: 'Z2', data: [0,0], backgroundColor: '#B56F00' }
    const z3 = { label: 'Z3', data: [0,0], backgroundColor: '#ffd500' }
    const z4 = { label: 'Z4', data: [0,0], backgroundColor: '#14558B' }
    const z5 = { label: 'Z5', data: [0,0], backgroundColor: '#298021' }

    const arrayZ = [z1, z2, z3, z4, z5]

    if (data?.Resultados) {
      data?.Resultados?.forEach(item => {
        if (item?.Z == 1) {
          z1.data = [item?.Homens, item?.Mulheres]
        }
        if (item?.Z == 2) {
          z2.data = [item?.Homens, item?.Mulheres]
        }
        if (item?.Z == 3) {
          z3.data = [item?.Homens, item?.Mulheres]
        }
        if (item?.Z == 4) {
          z4.data = [item?.Homens, item?.Mulheres]
        }
        if (item?.Z == 5) {
          z5.data = [item?.Homens, item?.Mulheres]
        }
      })
    }


    const datasets = arrayZ.map(item => ({
      label: item.label,
      data: item.data,
      backgroundColor: item.backgroundColor,
    }))
    
    // Criar o gráfico de barra
    this.chart = new Chart("ChartConsultarNiveisPacientesPorGenero", {
      type: 'bar',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }
    });

    this.chart.update()
  }

}
