import { DateTime } from 'luxon';
import { Component } from '@angular/core';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { AnamneseService } from '@services/dashboard/anamnese/anamnese.service';
import { FormControl, FormGroup } from '@angular/forms';
import { ApiService } from '@services/api.service';
import { DadosCadastraisService } from '@services/dashboard/dados-cadastrais/dados-cadastrais.service';
import { AppService } from '@services/app.service';

@Component({
  selector: 'app-meus-dados',
  templateUrl: './meus-dados.html',
  styleUrls: ['./meus-dados.scss']
})
export class MeusDados {
  public stepperSelected: string = 'Cadastro';
  public loading: boolean = false;
  public usuarioLogado = null;
  public usuario = null;
  public steppers = [
    { name: 'Cadastro', isSelected: false },
    /* { name: 'Plano', isSelected: false },
    { name: 'Checkups', isSelected: false }, */
   ];
 
 
  public opcoesSexo = [
    { value: 3, label: 'Não Informar' },
    { value:  1, label: 'Masculino' },
    { value: 2, label: 'Feminino' },
  ];

  public opcoesEstadoCivil = [
    { value: 1, label: 'Solteiro(a)' },
    { value: 2, label: 'Casado(a)' },
    { value: 3, label: 'União Estável' },
    { value: 4, label: 'Divorciado(a)' },
    { value: 5, label: 'Viúvo(a)' },
    { value: 6, label: 'Outros' },
  ];

  public opcoesRaca = [
    { value: 0, label: 'Não Informar' },
    { value: 1, label: 'Caboclo(a)' },
    { value: 2, label: 'Mulato(a)' },
    { value: 3, label: 'Cafuzo(a)' },
    { value: 4, label: 'Branco(a)' },
    { value: 5, label: 'Negro(a)' },
    { value: 6, label: 'Índio(a)' }
  ]

  public grauEscolaridade = [
    { value: 0, label: 'Não Informar' },
    { value: 1, label: 'Ensino Fundamental' },
    { value: 2, label: 'Ensino Médio' },
    { value: 3, label: 'Ensino Superior' },
    { value: 4, label: 'Pós Graduação' },
    { value: 5, label: 'Doutorado' },
    { value: 6, label: 'Pós Doutorado' }
  ]

  public opcoesPerfil = [
    
    { value: 2, label: 'Cliente' },
    
  ]

  public opcoesCorrespondencia = [
    { value: true, label: 'Sim' },
    { value: false, label: 'Não' },
  ]

  public etapa1Data: FormGroup
  public etapa2Data: FormGroup
  public etapa3Data: FormGroup
 

  constructor(private professorService: ProfessorService,private toastr: ToastrService,
    private anamneseService: AnamneseService, private apiservice: ApiService,
    public dadosCadastraisService: DadosCadastraisService,
    private appService: AppService
  
  ) {}

  ngOnInit(): void {
    //obter dados clientes
    this.usuarioLogado = this.appService.getDadosUsuarioLogadoStorage()
    if (this.usuarioLogado?.ID) {
    this.usuario = this.professorService.getObterDadosCadastro(this.usuarioLogado.ID).subscribe((res: any) => {
      this.etapa1Data.patchValue({
        AceitaCorrespondencia: res.AceitaCorrespondencia,
        Ativo: res.Ativo,
        Nome: res.Nome,
        Login: res.Login,
        TelefoneCelular: res.TelefoneCelular,
        TelefoneFixo: res.TelefoneFixo,
        Email: res.Email,
        DataNascimento: DateTime.fromISO(res.DataNascimento).toISODate(),
        Ocupacao: res.Ocupacao,
        Sexo: res.Sexo,
        EstadoCivil: res.EstadoCivil,
        Raca: res.Raca,
        GrauEscolaridade: res.GrauEscolaridade,
        CEP: res.CEP,
        Cidade: res.Cidade,
        UFEndereco: res.UFEndereco,
        Bairro: res.Bairro,
        Logradouro: res.Logradouro,
        Numero: res.Numero,
        PerfilID: res.PerfilID,
        Complemento: res.Complemento,
      });
    })
    }

    
    //inicio do form
    this.etapa1Data = new FormGroup({
      AceitaCorrespondencia: new FormControl(''),
      Ativo: new FormControl(true),
      Nome: new FormControl(''),
      Login: new FormControl(''),
      TelefoneCelular: new FormControl(''),
      TelefoneFixo: new FormControl(''),
      Email: new FormControl(''),
      DataNascimento: new FormControl(''),
      Ocupacao: new FormControl(''),
      Sexo: new FormControl(''),
      EstadoCivil: new FormControl(''),
      Raca: new FormControl(''),
      GrauEscolaridade: new FormControl(''),
      CEP: new FormControl(''),
      Cidade: new FormControl(''),
      UFEndereco: new FormControl(''),
      Bairro: new FormControl(''),
      Logradouro: new FormControl(''),
      Numero: new FormControl(''),
      PerfilID: new FormControl(''),
      Complemento: new FormControl(''),

    });
  }
 
      
  
  onSelectStepper(stepperSelected: string) {
    this.stepperSelected = stepperSelected;
    
  }

  onSalvar() {

    if (!this.etapa1Data.value.Nome || this.etapa1Data.value.Nome === '' ||
        !this.etapa1Data.value.Login || this.etapa1Data.value.Login === '' ||
        !this.etapa1Data.value.TelefoneCelular || this.etapa1Data.value.TelefoneCelular === '' ||
         !this.etapa1Data.value.Email || this.etapa1Data.value.Email === '' ||
        !this.etapa1Data.value.DataNascimento || this.etapa1Data.value.DataNascimento === '' ||
        !this.etapa1Data.value.Ocupacao || this.etapa1Data.value.Ocupacao === '' ||
        !this.etapa1Data.value.Sexo || this.etapa1Data.value.Sexo === '' ||
        !this.etapa1Data.value.EstadoCivil || this.etapa1Data.value.EstadoCivil === '' ||
        !this.etapa1Data.value.Raca || this.etapa1Data.value.Raca === '' ||
        !this.etapa1Data.value.GrauEscolaridade || this.etapa1Data.value.GrauEscolaridade === '' ||
        !this.etapa1Data.value.CEP || this.etapa1Data.value.CEP === '' ||
        !this.etapa1Data.value.Cidade || this.etapa1Data.value.Cidade === '' ||
        !this.etapa1Data.value.UFEndereco || this.etapa1Data.value.UFEndereco === '' ||
        !this.etapa1Data.value.Bairro || this.etapa1Data.value.Bairro === '' ||
        !this.etapa1Data.value.Logradouro || this.etapa1Data.value.Logradouro === '' ||
         !this.etapa1Data.value.PerfilID || this.etapa1Data.value.PerfilID === ''
      ) {
      this.toastr.error('Preencha todos os campos.','Erro');
      return;
    }
    

    
    
    this.loading = true;
    
    let dadosParaSalvar = {
      ...this.etapa1Data.value,
      ID: this.usuarioLogado.ID,
      DataNascimento: DateTime.fromISO(this.etapa1Data.value.DataNascimento).toISODate(),
      PerfilID: 2,
      Sexo: parseInt(this.etapa1Data.value.Sexo),
      EstadoCivil: parseInt(this.etapa1Data.value.EstadoCivil),
      Raca: parseInt(this.etapa1Data.value.Raca),
      GrauEscolaridade: parseInt(this.etapa1Data.value.GrauEscolaridade),
      AceitaCorrespondencia: this.etapa1Data.value.AceitaCorrespondencia === 'true' ? true : false,
      Ativo: this.etapa1Data.value.Ativo === 'true' ? true : false,
    }
      

    this.professorService.postAtualizarDadosCadastrais(dadosParaSalvar).subscribe({ 
      next: (res: any) => {
        this.toastr.success('Dados atualizados com sucesso!','Sucesso');
        this.loading = false;
        // window.history.back();
      },
      error: (erro: any) => {
        this.toastr.error('Erro ao atualizar dados!','Erro');
        this.loading = false;
      }
    });

  } 

  onSair() {
    window.history.back();
  }

  buscarEnderecoPorCEP() {
    var cep = this.etapa1Data.value.CEP.replace(/\D/g, '');
    if (cep != "") {
      var validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        this.apiservice.buscarViaCEP(cep).subscribe((dados: any) => {
          this.etapa1Data.patchValue({
            Logradouro: dados.logradouro,
            Bairro: dados.bairro,
            Cidade: dados.localidade,
            UFEndereco: dados.uf
          });
        });
      }
    }
      
  }

  getCurrentDate(): string {
    const today = new Date();
    const month = today.getMonth() + 1 < 10 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1;
    const day = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
    return `${today.getFullYear()}-${month}-${day}`;
  }


}

   

