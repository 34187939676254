import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {getHeaders} from "@/utils/getHeaders";
import {environment} from "../../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class RelatorioCadastroService {

    private api = environment.endpoint_api

    constructor(private http: HttpClient) {
    }

    getRelatorioCheckupsPeriodo(body) {
        const headers = getHeaders()
        return this.http.post(this.api + '/Relatorio/RelatorioCheckupPorPeriodo', body, {headers})
    }

    getRelatorioAniversariantes(body) {
        const headers = getHeaders()
        return this.http.get(this.api + `/Relatorio/ObterAniversariantesMes/${body.mesID}`, {headers})
    }

    getRelatorioEvolucao(body) {
        const headers = getHeaders()
        return this.http.post(this.api + '/Relatorio/RelatorioEvolucao', body, {headers})
    }
    
    postRelatorioRanking(body) {
        const headers = getHeaders()
        return this.http.post(this.api + '/Relatorio/ObterRankingAvaliadores', body, {headers})
    }
}
