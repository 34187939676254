import {Component, ElementRef, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ModalBioAnteriorComponent} from '@components/dashboard/modais/Modal-BioAnterior/modal-bioAnterior.component';
import {ModalRetaFinalComponent} from '@components/dashboard/modais/Modal10-RetaFinal/modal-retaFinal.component';
import {
    ModalDadosPessoaisComponent
} from '@components/dashboard/modais/Modal2-DadosPessoais/modal-dadospessoais.component';
import {
    ModalQuestFarmPrelimComponent
} from '@components/dashboard/modais/Modal4-QuestFarmPrelim/modal-questFarmPrelim.component';
import {ModalCheckBoxesComponent} from '@components/dashboard/modais/Modal5-CheckBoxes/modal-checkBoxes.component';
import {ModalPercepSaudeComponent} from '@components/dashboard/modais/Modal6-PercepSaude/modal-percepSaude.component';
import {
    ModalSaudEmocFuncComponent
} from '@components/dashboard/modais/Modal7-SaudEmocFunc/modal-saudEmocFunc.component';
import {ModalCheckBoxes2Component} from '@components/dashboard/modais/Modal8-CheckBoxes2/modal-checkBoxes2.component';
import {
    ModalCheckBoxes3SonoComponent
} from '@components/dashboard/modais/Modal9-CheckBoxes3Sono/modal-checkBoxes3Sono.component';
import {Modal1InicioComponent} from '@components/dashboard/modais/modal1-Inicio/modal-1inicio.component';
import {
    ModalInfoSobreSaudeComponent
} from '@components/dashboard/modais/modal3-InfoSobreSaude/modal-infoSobreSaude.component';
import {AnamneseService} from '@services/dashboard/anamnese/anamnese.service';
import {ToastrService} from 'ngx-toastr';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-biomarcadores',
    templateUrl: './biomarcadores.component.html',
    styleUrls: ['./biomarcadores.component.scss']
})
export class BiomarcadoresComponent implements OnInit, OnDestroy {
    @ViewChild('list', {static: false}) list: ElementRef;
    @Input() usuarioId: number | string = ""


    private anamneseSubscription: Subscription
    public desativarBiomarcadores: boolean = true
    public biomarcadores = []
    public urlLaudo: string = ""

    constructor(
        private anamneseService: AnamneseService,
        private toastr: ToastrService,
    ) {
    }


    ngOnInit(): void {
        const classeContexto = this
        this.montarBiomarcadores()
        this.anamneseSubscription = this.anamneseService.ananmese$.subscribe(novoValor => {

            if (novoValor?.ID) {
                // baixar laudo
                this.urlLaudo = `https://api.plataformasafe.com.br/home/DownloadLaudoCompleto/${novoValor?.IdentificadorHash}`

                // validar anamnese
                this.anamneseService.validarAnamnese(novoValor).subscribe({
                    next(response) {
                        classeContexto.montarBiomarcadores(response)
                    },
                    error() {
                        classeContexto.toastr.error("Ocorreu um erro", "Ocorreu um erro ao validar o preenchimento da anamnese")
                    }
                })
                this.desativarBiomarcadores = false
            } else {
                this.desativarBiomarcadores = true
            }
        })
    }

    ngOnDestroy(): void {
        // Certifique-se de cancelar a inscrição para evitar vazamentos de memória
        this.anamneseSubscription.unsubscribe();
        this.anamneseService.setAnamnese({})
        this.anamneseService.setAnamneseDadosValidarEtapas({})
    }


    scrollPrevious() {
         this.list.nativeElement.scrollLeft -= 250; // Ajuste o valor conforme necessário
    }

    scrollNext() {
 
        this.list.nativeElement.scrollLeft += 250; // Ajuste o valor conforme necessário
    }

    montarBiomarcadores(resultadoValidacao: any = {}) {

        // active - se foi preenchido ou não
        let etapa1 = false
        let etapa2 = false
        let etapa3 = false
        let etapa4 = false
        let etapa5 = false
        let etapa6 = false
        let etapa7 = false
        let etapa8 = false
        let etapa9 = false
        let etapa10 = false

        if (resultadoValidacao?.etapas?.length > 0) {
            etapa1 = !!resultadoValidacao?.etapas[0]?.validada
            etapa2 = !!resultadoValidacao?.etapas[1]?.validada
            etapa3 = !!resultadoValidacao?.etapas[2]?.validada
            etapa4 = !!resultadoValidacao?.etapas[3]?.validada
            etapa5 = !!resultadoValidacao?.etapas[4]?.validada
            etapa6 = !!resultadoValidacao?.etapas[5]?.validada
            etapa7 = !!resultadoValidacao?.etapas[6]?.validada
            etapa8 = !!resultadoValidacao?.etapas[7]?.validada
            etapa9 = !!resultadoValidacao?.etapas[8]?.validada
            etapa10 = !!resultadoValidacao?.etapas[9]?.validada
        }

        this.biomarcadores = [
            {
                titulo: "Início",
                iconPath: "saude-geral.svg",
                active: etapa1,
                modal: Modal1InicioComponent,
                nextModal: ModalDadosPessoaisComponent,
                previousModal: null
            },
            {
                titulo: "Dados Pessoais",
                iconPath: "limitacoes-por-aspecto-fisico.svg",
                active: etapa2,
                modal: etapa1 ? ModalDadosPessoaisComponent : ModalBioAnteriorComponent,
                previousModal: null
            },
            {
                titulo: "Informações sobre saúde",
                iconPath: "capacidade-funcional-2.svg",
                active: etapa3,
                modal: etapa2 ? ModalInfoSobreSaudeComponent : ModalBioAnteriorComponent,
                previousModal: null
            },
            {
                titulo: "Questionário Farmacêutico Preliminar",
                iconPath: "quest-farm-prel.svg",
                active: etapa4,
                modal: etapa3 ? ModalQuestFarmPrelimComponent : ModalBioAnteriorComponent,
                previousModal: null
            },
            {
                titulo: "Checklist",
                iconPath: "bio-checklist.svg",
                active: etapa5,
                modal: etapa4 ? ModalCheckBoxesComponent : ModalBioAnteriorComponent,
                previousModal: null
            },
            {
                titulo: " Percepção saúde",
                iconPath: "percepcao-saude.svg",
                active: etapa6,
                modal: etapa5 ? ModalPercepSaudeComponent : ModalBioAnteriorComponent,
                previousModal: null
            },
            {
                titulo: "Saúde emocional saúde funcional",
                iconPath: "bio-checklist.svg",
                active: etapa7,
                modal: etapa6 ? ModalSaudEmocFuncComponent : ModalBioAnteriorComponent,
                previousModal: null

            },
            {
                titulo: "CheckList 2",
                iconPath: "checklist2.svg",
                active: etapa8,
                modal: etapa7 ? ModalCheckBoxes2Component : ModalBioAnteriorComponent,
                previousModal: null
            },
            {
                titulo: "Sono",
                iconPath: "sono.svg",
                active: etapa9,
                modal: etapa8 ? ModalCheckBoxes3SonoComponent : ModalBioAnteriorComponent,
                previousModal: null
            },
            {
                titulo: "Reta Final",
                iconPath: "reta-final.svg",
                active: etapa10,
                modal: etapa9 ? ModalRetaFinalComponent : ModalBioAnteriorComponent,
                previousModal: null
            },
        ]
    }

    onWheel(event: WheelEvent) {
        event.preventDefault()
        this.list.nativeElement.scrollBy({
            left: event.deltaY < 0 ? -50 : 50,
        });
    }
}
