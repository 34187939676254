import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppService } from '@services/app.service';
import { ProfessorService } from '@services/dashboard/professor/professor.service';
import { ToastrService } from 'ngx-toastr';
import { DateTime } from 'luxon';
import { ApiService } from '@services/api.service';

@Component({
  selector: 'app-modal-termine-cadastro',
  templateUrl: './modal-termine-cadastro.component.html',
  styleUrls: ['./modal-termine-cadastro.component.scss']
})
export class ModalTermineCadastroComponent implements OnInit {
  public etapa1Data = new FormGroup({
    ID: new FormControl(''),
    AceitaCorrespondencia: new FormControl(''),
    Ativo: new FormControl(true),
    Nome: new FormControl(''),
    Login: new FormControl(''),
    TelefoneCelular: new FormControl(''),
    TelefoneFixo: new FormControl(''),
    Email: new FormControl(''),
    DataNascimento: new FormControl(''),
    Ocupacao: new FormControl(''),
    Sexo: new FormControl(''),
    EstadoCivil: new FormControl(''),
    Raca: new FormControl(''),
    GrauEscolaridade: new FormControl(''),
    CEP: new FormControl(''),
    Cidade: new FormControl(''),
    UFEndereco: new FormControl(''),
    Bairro: new FormControl(''),
    Logradouro: new FormControl(''),
    Numero: new FormControl(''),
    PerfilID: new FormControl(''),
    Complemento: new FormControl(''),
    PlanoID: new FormControl(''),
    IdentificadorHash: new FormControl(''),
    UsuarioInclusao: new FormControl(''),
    PerfilIDPreferencial: new FormControl(''),
    DataInclusao: new FormControl(''),
    UsuarioAlteracao: new FormControl(''),
    DataAlteracao: new FormControl(''),
    Idade: new FormControl(''),
  })
  public user: any;
  public isLoadingSalvar = false;
  public loadingDadosUsuario = false;
  public opcoesSexo = [
    { value: 3, label: 'Não Informar' },
    { value:  1, label: 'Masculino' },
    { value: 2, label: 'Feminino' },
  ];

  public opcoesEstadoCivil = [
    { value: 1, label: 'Solteiro(a)' },
    { value: 2, label: 'Casado(a)' },
    { value: 3, label: 'União Estável' },
    { value: 4, label: 'Divorciado(a)' },
    { value: 5, label: 'Viúvo(a)' },
    { value: 6, label: 'Outros' },
  ];

  public opcoesRaca = [
    { value: 0, label: 'Não Informar' },
    { value: 1, label: 'Caboclo(a)' },
    { value: 2, label: 'Mulato(a)' },
    { value: 3, label: 'Cafuzo(a)' },
    { value: 4, label: 'Branco(a)' },
    { value: 5, label: 'Negro(a)' },
    { value: 6, label: 'Índio(a)' }
  ]

  public grauEscolaridade = [
    { value: 0, label: 'Não Informar' },
    { value: 1, label: 'Ensino Fundamental' },
    { value: 2, label: 'Ensino Médio' },
    { value: 3, label: 'Ensino Superior' },
    { value: 4, label: 'Pós Graduação' },
    { value: 5, label: 'Doutorado' },
    { value: 6, label: 'Pós Doutorado' }
  ]

 
  
  constructor(private professorService: ProfessorService,private toastr: ToastrService,
    private appService: AppService, private apiservice: ApiService,
  ) {}

  ngOnInit(): void {
    this.getDadosUsuario();
  }

  buscarEnderecoPorCEP() {
    var cep = this.etapa1Data.value.CEP.replace(/\D/g, '');
    if (cep != "") {
      var validacep = /^[0-9]{8}$/;
      if (validacep.test(cep)) {
        this.apiservice.buscarViaCEP(cep).subscribe((dados: any) => {
          this.etapa1Data.patchValue({
            Logradouro: dados.logradouro,
            Bairro: dados.bairro,
            Cidade: dados.localidade,
            UFEndereco: dados.uf
          });
        });
      }
    }
      
  }

  getDadosUsuario(): void {
    let id = JSON.parse(localStorage.getItem('user'))?.ID
  
    this.loadingDadosUsuario = true;
    this.professorService.getObterDadosCadastro(id).subscribe({
      next: (response : any) => {
        this.etapa1Data = new FormGroup({
          ID: new FormControl(response.ID),
          AceitaCorrespondencia: new FormControl(response.AceitaCorrespondencia),
          Ativo: new FormControl(response.Ativo),
          Nome: new FormControl(response.Nome),
          Login: new FormControl(response.Login),
          TelefoneCelular: new FormControl(response.TelefoneCelular),
          TelefoneFixo: new FormControl(response.TelefoneFixo),
          Email: new FormControl(response.Email),
          DataNascimento: new FormControl(DateTime.fromISO(response.DataNascimento).toISODate()),
          Ocupacao: new FormControl(response.Ocupacao),
          Sexo: new FormControl(response.Sexo),
          EstadoCivil: new FormControl(response.EstadoCivil),
          Raca: new FormControl(response.Raca),
          GrauEscolaridade: new FormControl(response.GrauEscolaridade),
          CEP: new FormControl(response.CEP),
          Cidade: new FormControl(response.Cidade),
          UFEndereco: new FormControl(response.UFEndereco),
          Bairro: new FormControl(response.Bairro),
          Logradouro: new FormControl(response.Logradouro),
          Numero: new FormControl(response.Numero),
          PerfilID: new FormControl(response.PerfilID),
          Complemento: new FormControl(response.Complemento),
          PlanoID: new FormControl(response.PlanoID),
          Idade: new FormControl(response.Idade),
          IdentificadorHash: new FormControl(response.IdentificadorHash),
          PerfilIDPreferencial: new FormControl(response.PerfilID),
          UsuarioInclusao: new FormControl(response?.Avaliadores[0]?.Avaliador?.UsuarioInclusao),
          DataInclusao: new FormControl(response?.Avaliadores[0]?.Avaliador?.DataInclusao),
          UsuarioAlteracao: new FormControl(response?.Avaliadores[0]?.Avaliador?.UsuarioAlteracao),
          DataAlteracao: new FormControl(response?.Avaliadores[0]?.Avaliador?.DataAlteracao),

        });
        
        this.loadingDadosUsuario = false;
      },
      error: (error) => {
        if (error?.error?.Message) {
          this.toastr.error(error.error.Message);
        } else {
          this.toastr.error('Erro ao buscar dados do usuário, contate o suporte')
        }
        this.loadingDadosUsuario = false;
      }
    });
  }

  handleClickFecharModal() {
    this.appService.closeModal()
  }

  onSalvar() {


    let dadosParaSalvar = {
      ...this.etapa1Data.value,
      DataNascimento: DateTime.fromISO(this.etapa1Data.value.DataNascimento).toISODate(),
      PerfilID: parseInt(this.etapa1Data.value.PerfilID),
      Sexo: parseInt(this.etapa1Data.value.Sexo),
      EstadoCivil: parseInt(this.etapa1Data.value.EstadoCivil),
      Raca: parseInt(this.etapa1Data.value.Raca),
      GrauEscolaridade: parseInt(this.etapa1Data.value.GrauEscolaridade),
      AceitaCorrespondencia: false,
      Ativo: true,
       PerfilIDPreferencial: parseInt(this.etapa1Data.value.PlanoID),
    }
 

    this.isLoadingSalvar = true;
    this.professorService.postAtualizarDadosCadastrais(dadosParaSalvar).subscribe({
      next: (res: any) => {
        this.toastr.success('Usuário salvo com sucesso');
        this.isLoadingSalvar = false;
        this.appService.closeModal()
      },
      error: (erro: any) => {
        if (erro && erro.error && erro.error.Message) {
          const messages = JSON.parse(erro.error.Message);
          if (Array.isArray(messages)) {
            // Itera sobre cada mensagem de erro e exibe no Toastr
            messages.forEach((mensagem: any) => {
              if (mensagem.Mensagem) {
                this.toastr.error(mensagem.Mensagem, 'Erro ao salvar os dados');
              }
            });
          }
        } else {
          // Caso o formato de erro seja inesperado, exibe uma mensagem genérica
          this.toastr.error('Erro ao salvar os dados. Tente novamente mais tarde.');
          console.error('Erro ao processar a resposta de erro:', erro);
        }
        this.isLoadingSalvar = false;
      }
    });

  }
}
