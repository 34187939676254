import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalTermineCadastroComponent } from '@components/dashboard/modais/ModalTermineCadastro/modal-termine-cadastro.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from "@services/app.service";
import { AnamneseService } from "@services/dashboard/anamnese/anamnese.service";
import { CheckupClienteService } from "@services/dashboard/checkup-cliente/checkup-cliente.service";
import { DadosCadastraisService } from "@services/dashboard/dados-cadastrais/dados-cadastrais.service";
import { ShepherdService } from 'angular-shepherd';
import { Subscription } from "rxjs";


const tour = {
    defaultStepOptions: {
      scrollTo: true,
      cancelIcon: {
        enabled: true
      },
      canClickTarget: true,
      popperOptions: {
        modifiers: [
          {
            name: "offset",
            options: {
              offset: [0, 20]
            }
          }
        ]
      },
      modalOverlayOpeningPadding: 8,
      modalOverlayOpeningRadius: 4
    },
    steps: [
      {
        id: "body-safe",
        attachTo: {
          element: ".body-safe",
          on: "bottom"
        },
        buttons: [
         
          {
            classes: "shepherd-button-primary-voltar",
            text: "Voltar",
            type: "back"
          },
          {
            classes: "shepherd-button-primary-proximo",
            text: "Próximo",
            type: "next"
          }
        ],
        classes: "shepherd-content",
        title: "Bem vindo ao Tutorial SAFE",
        text: "Este é o seu Dashboard, aqui você terá acesso a todas as informações sobre o seu CHECK-UP SAFE®."
      },
      {
        id: "biomarcadores",
        attachTo: {
          element: ".home-biomarcadores",
          on: "bottom"
        },
        buttons: [
          
          {
            classes: "shepherd-button-primary-voltar",
            text: "Voltar",
            type: "back"
          },
          {
            classes: "shepherd-button-primary-proximo",
            text: "Próximo",
            type: "next"
          }
        ],
        classes: "shepherd-content",
        title: " CHECK-UP",
        text: " Para começar, inicie o seu CHECK-UP clicando em Início e preencha todas as informações solicitadas."
      },
      {
        id: "resultado",
        attachTo: {
          element: ".home-resultado",
          on: "bottom"
        },
        buttons: [
          
          {
            classes: "shepherd-button-primary-voltar",
            text: "Voltar",
            type: "back"
          },
          {
            classes: "shepherd-button-primary-proximo",
            text: "Próximo",
            type: "next"
          }
        ],
        classes: "shepherd-content",
        title: "Resultado SAFE®",
        text: "Após preencher todas as informações, você terá acesso ao seu resultado SAFE®."
      },
      {
        id: "piramide",
        attachTo: {
          element: ".home-piramide",
          on: "bottom"
        },
        buttons: [
          
          {
            classes: "shepherd-button-primary-voltar",
            text: "Voltar",
            type: "back"
          },
          {
            classes: "shepherd-button-primary-proximo",
            text: "Próximo",
            type: "next"
          }
        ],
        classes: "shepherd-content",
        title: "Pirâmide SAFE®",
        text: "Também será possível visualizar a sua pirâmide SAFE®, com sua classificação do estilo de vida atual."
      },
      {
        id: "laudo",
        attachTo: {
          element: ".home-laudo",
          on: "bottom"
        },
        buttons: [
          
           {
            classes: "cancel-button-sair",
            text: "Fechar",
            type: "cancel"
          },
        ],
        classes: "shepherd-content",
        title: "Laudo SAFE®",
        text: "O laudo SAFE® também estará disponível para você, com todas as informações sobre o seu CHECK-UP."
      },

    ]
  };
@Component({
    selector: 'app-dashboard-aluno',
    templateUrl: './dashboard-aluno.component.html',
    styleUrls: ['./dashboard-aluno.component.scss']
})
export class DashboardAlunoComponent implements OnInit, OnDestroy {
    @Input() usuarioId: number | string = ""
    @Input() public isAlunoAvaliador: boolean = false
    public desativarCamposAnamnese: boolean = true
    public loadingAnamnese: boolean = false;
    public tipoZ: number = 0
    public imcAluno: string = "Sem informação"
    public idadeBiologicaAlunoCardio: string = "Sem informação"
    public idadeBiologicaAlunoForca: string = ""
    public loadingIsAlunoAvaliador: boolean = false
    public dadosCadastrais: any = {}
    prescricaoCandidato = {
        nivel: "", recomendacao: ""
    }
    prescricoesEstiloDeVida = {
        1: {
            nivel: "Muito abaixo do desejável",
            recomendacao: "A partir dos resultados, recomendamos, que neste momento da sua vida, tome algumas atitudes para melhorar a qualidade do seu Estilo de Vida e, consequentemente da sua Saúde Funcional."
        }, 2: {
            nivel: "Abaixo do desejável",
            recomendacao: "Recomendamos que Você tome algumas atitudes para melhorar os biomarcadores SAFE destacados nos resultados e para que Você possa ter um estilo de vida mais saudável."
        }, 3: {
            nivel: "Regular",
            recomendacao: "Recomendamos que Você dê prioridade aos biomacadores SAFE que foram avaliados abaixo do ideal para melhorar o seu Estilo de Vida."
        }, 4: {
            nivel: "Bom",
            recomendacao: "Você está próximo do Z5. Recomendamos apenas que tenha um pouco mais de foco na melhora dos biomacadores SAFE que precisam ser melhorados."
        }, 5: {
            nivel: "Excelente",
            recomendacao: "Parabéns! Mantenha-se no Patamar Z5 e não se esqueça de estimular outras pessoas do seu ciclo social a ter a mesma atitude em relação à qualidade da saúde."
        },
    }
    private checkupSubscription: Subscription;
    private anamneseSubscription: Subscription
    private loadingAnamnseSubscription: Subscription;
    private dadosCadastraisSubscription: Subscription;

    constructor(
        private guideTour: ShepherdService,
        private appService: AppService,
        private checkupClienteService: CheckupClienteService,
        public dadosCadastraisService: DadosCadastraisService,
        public anamneseService: AnamneseService,
        private modalService: NgbModal  // Injeção do serviço de modal
      ) {
    }

  
    
    ngOnInit(): void {
        const classeContexto = this
        if (this.isAlunoAvaliador) {
            this.loadingIsAlunoAvaliador = true
        }
        this.desativarCamposAnamnese = true
        this.anamneseSubscription = this.anamneseService.ananmese$.subscribe(novoValor => {

          if (novoValor?.ID) {
              this.desativarCamposAnamnese = false
          }
        })
        
     

        // caso não seja passado o parametro usuarioId, o usuário id padrão será o do usuário logado
        this.usuarioId = this.usuarioId ? this.usuarioId : this.appService.getDadosUsuarioLogadoStorage()?.ID

        this.dadosCadastraisService.getDadosCadastrais(this.usuarioId)
        this.dadosCadastraisService.getPerfis()
        this.checkupClienteService.getCheckupCliente(this.usuarioId)

        this.dadosCadastraisSubscription = this.dadosCadastraisService.dadosCadastraisPublico$.subscribe({
            next: (dados) => {
                this.dadosCadastrais = dados;
                if (this.dadosCadastrais?.ID && (!this.dadosCadastrais?.Login || this.dadosCadastrais?.Login === "")) {
                    this.abrirModalDadosPessoais();
                }
            },
            error: (err) => {
                console.error('Erro ao obter dados cadastrais:', err);
            }
        });
 
        // escuta a mudanca de estado do checkup
        this.checkupSubscription = this.checkupClienteService.checkup$.subscribe((novoValor) => {
            if (novoValor?.HashAnamnese) {
                this.anamneseService.getAnamneseRequest(novoValor?.HashAnamnese)
                this.anamneseService.retornarResultadoAnamnese(novoValor?.HashAnamnese).subscribe({
                    next(response) {
                        // @ts-ignore
                        const imcDados = response.find(item => item?.BiomarcadorID == 29);
                        // @ts-ignore
                        const idadeBiologicaDadosCardio = response.find(item => item?.BiomarcadorID == 55);
                        // @ts-ignore
                        const idadeBiologicaDadosForca = response.find(item => item?.BiomarcadorID == 54);


                        // ts-ignore
                        classeContexto.imcAluno = imcDados?.ValorAlcancado || "Sem informação"
                        let spanDescricaoImcMenuSidebar = document.getElementById("span-descricao-imc-menu-sidebar")
                        if (spanDescricaoImcMenuSidebar) {
                            spanDescricaoImcMenuSidebar.innerHTML = imcDados?.ValorAlcancado ? `${imcDados?.ValorAlcancado}KG/M²` : "Sem informação"
                        }

                        // cardio
                        // ts-ignore
                        const idadeBioCardio = idadeBiologicaDadosCardio?.ValorAlcancado ? JSON.parse(idadeBiologicaDadosCardio?.ValorAlcancado) : {}
                        // ts-ignore
                        classeContexto.idadeBiologicaAlunoCardio = idadeBioCardio?.idadeBiologicaAtual || 'Sem informação'

                        // força
                        // ts-ignore
                        // const idadeBioForca = idadeBiologicaDadosCardio?.ValorAlcancado ? JSON.parse(idadeBiologicaDadosForca?.ValorAlcancado) : {}
                        // ts-ignore
                        classeContexto.idadeBiologicaAlunoForca = idadeBiologicaDadosForca?.ValorAlcancado || 'Sem informação'


                    }, error() {

                    }
                })
            }
        });

        this.anamneseSubscription = this.anamneseService.ananmese$.subscribe(novoValor => {
            if (novoValor?.Z) {
                this.tipoZ = novoValor?.Z
                this.prescricaoCandidato = this.prescricoesEstiloDeVida[novoValor?.Z]
            }
            if (novoValor?.Z == 0) {
                this.tipoZ = 0
                this.prescricaoCandidato = {
                    nivel: "", recomendacao: "Sem informação"
                }
            }
        })

         this.loadingAnamnseSubscription = this.anamneseService.loadingAnamnse$.subscribe(novoValor => {
            this.loadingAnamnese = novoValor
        })

        this.loadingIsAlunoAvaliador = false

    }

    
    startTour() {
      this.guideTour.defaultStepOptions = tour.defaultStepOptions;
      this.guideTour.modal = true;
      this.guideTour.confirmCancel = false;
              //@ts-ignore
      this.guideTour.addSteps(tour.steps);
      this.guideTour.start();
      }

    abrirModalDadosPessoais() {
         if (this.modalService.hasOpenModals()) {
            return;   
        }
      
         const modalRef = this.appService.openModal(ModalTermineCadastroComponent, {size: 'lg', backdrop: false});

     }

    ngOnDestroy(): void {
         this.checkupSubscription.unsubscribe();
        this.loadingAnamnseSubscription.unsubscribe()
        this.anamneseSubscription.unsubscribe()
        this.isAlunoAvaliador = false
        this.usuarioId = ""
    }
} 
