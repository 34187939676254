<div class="modal-biomarcador">
    <div class="modal-backdrop"></div>

    <header class="bg-light-blue modal-biomarcador-header d-flex">
        <div style="flex: 1;" class="d-flex justify-content-center align-items-center">
            <div>
                <div>
                    <span class="modal-biomarcador-header-safe">SAFE®</span>
                </div>
                <div>
                    <span class="text-yellow modal-biomarcador-header-tipo-biomarcador">
                        Termine seu cadastro!
                    </span>
                </div>
            </div>
            <div style="padding-left: 20px;">
                <img src="assets/svg/biomarcador-1.svg" alt="">
            </div>
        </div>

        <div style="flex: 1;" class="d-flex justify-content-end align-items-center ">
           
        </div>


        <div class="fechar-modal" (click)="handleClickFecharModal()">
            <img src="assets/svg/close-icon.svg" alt="">
        </div>
    </header>
    <div class="modal-bio-form content bg-light p-4">
        <h1 class="form-title">Dados Pessoais</h1>
        <span class="form-label-texts-helper p-3">
            Bem vindo! Para finalizar seu cadastro, precisamos de algumas informações adicionais.
        </span>
        <div class="row vertical-content">
            <form class="form-form p-3"  [formGroup]="etapa1Data">
                <div class="col-md-12 row">
                    <div class="col-md-5 mt-2">
                      <label class="form-label-texts">Nome Completo</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Nome" />
                    </div>
                    <div class="col-md-5 mt-2">
                      <label class="form-label-texts">Login</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Login" />
                    </div>
                     <div class="col-md-4">
                      <label class="form-label-texts">Celular</label> 
                      <input type="text" class="form-control form-input-box" formControlName="TelefoneCelular" />
                    </div>
                    <div class="col-md-4">
                      <label class="form-label-texts">Telefone</label> 
                      <input type="text" class="form-control form-input-box" formControlName="TelefoneFixo" />
                    </div>
                    <div class="col-md-4">
                      <label class="form-label-texts">Email</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Email" />
                    </div>
                    <div class="col-md-4">
                      <label class="form-label-texts">Data de nascimento</label> 
                      <input type="date" class="form-control form-input-box" formControlName="DataNascimento" />
                    </div>
                    <div class="col-md-8">
                      <label class="form-label-texts">Ocupação atual</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Ocupacao" />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Sexo</label> 
                      <select class="form-control form-input-box" formControlName="Sexo">
                        <option *ngFor="let sexo of opcoesSexo" [value]="sexo.value"
                        [selected]="sexo.value === etapa1Data.controls['Sexo'].value"
                        >{{ sexo.label }}</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Estado civil</label> 
                      <select class="form-control form-input-box" formControlName="EstadoCivil">
                        <option *ngFor="let estdCivil of opcoesEstadoCivil" [value]="estdCivil.value"
                        [selected]="estdCivil.value === etapa1Data.controls['EstadoCivil'].value"
                        >{{ estdCivil.label }}</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Raça</label> 
                      <select class="form-control form-input-box" formControlName="Raca">
                        <option *ngFor="let rac of opcoesRaca" [value]="rac.value"
                        [selected]="rac.value === etapa1Data.controls['Raca'].value"
                        >{{ rac.label }}</option>
                      </select>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Grau de escolaridade</label> 
                      <select class="form-control form-input-box" formControlName="GrauEscolaridade">
                        <option *ngFor="let grau of grauEscolaridade" [value]="grau.value"
                        [selected]="grau.value === etapa1Data.controls['GrauEscolaridade'].value"
                        >{{ grau.label }}</option>
                      </select>
                    </div>
                    <div class="col-md-12 mt-2">
                      <div class="divider-hash"></div>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">CEP</label> 
                      <input type="text" class="form-control form-input-box"
                      (blur)="buscarEnderecoPorCEP()"
                      formControlName="CEP" />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Cidade</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Cidade" />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">UF</label> 
                      <select class="form-control form-input-box" formControlName="UFEndereco">
                          <option selected="selected" value="T">Todas</option>
                          <option value="AC">AC</option>
                          <option value="AL">AL</option>
                          <option value="AM">AM</option>
                          <option value="AP">AP</option>
                          <option value="BA">BA</option>
                          <option value="CE">CE</option>
                          <option value="DF">DF</option>
                          <option value="ES">ES</option>
                          <option value="GO">GO</option>
                          <option value="MA">MA</option>
                          <option value="MG">MG</option>
                          <option value="MS">MS</option>
                          <option value="MT">MT</option>
                          <option value="PA">PA</option>
                          <option value="PB">PB</option>
                          <option value="PE">PE</option>
                          <option value="PI">PI</option>
                          <option value="PR">PR</option>
                          <option value="RJ">RJ</option>
                          <option value="RN">RN</option>
                          <option value="RS">RS</option>
                          <option value="RO">RO</option>
                          <option value="RR">RR</option>
                          <option value="SC">SC</option>
                          <option value="SE">SE</option>
                          <option value="SP">SP</option>
                          <option value="TO">TO</option>
                       </select>
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Bairro</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Bairro" />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Logradouro</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Logradouro" />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Número</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Numero" />
                    </div>
                    <div class="col-md-3">
                      <label class="form-label-texts">Complemento</label> 
                      <input type="text" class="form-control form-input-box" formControlName="Complemento" />
                    </div>
                 
                      <div class="col-md-12 mt-2">
                          <div class="divider-hash"></div>
                      </div>
                      
                      
                      <div class="col-md-12 d-flex justify-content-end">
                          <button class="btn btn-secondary mr-3 border-radius-10 btn-md" (click)="handleClickFecharModal()"
                          >Fechar</button>
                          <button class="btn btn-primary mr-3 border-radius-10 btn-md" (click)="onSalvar()"
                          [disabled]="loadingDadosUsuario || isLoadingSalvar"
                          >
                          {{ isLoadingSalvar ? 'Salvando...' : 'Salvar' }}
                        </button>
                      </div>
                  </div>
            </form>
        </div>
    </div>
</div>