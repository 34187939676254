<div class="screen-container" style="overflow-y: scroll; max-height: 100vh;">
    <!-- <app-breadcrumb rotaAtiva="Home" linkRotaAtiva="/dashboard"></app-breadcrumb> -->


    <div class="container-fluid bg-transparent">
 
        <div>
            <div class="w-100 d-flex justify-content-between mt-5">
                <h5 class="home-dash mt-5">

                    Usuários Online
                </h5>
                <img alt="plat-safe-logo" height="122px" src="assets/img/logo-plat_branco(1).png" width="154px">
            </div>
            <div class="divider-dash"></div>
        </div>

        <div class="container-tabela">
            <h2 class="titulo-tabela-meus-clientes text-azul-claro-plataforma-safe">
                Usuários Online: {{ listaClientes.length }}
            </h2>

            <table class="table table-striped">
                <thead>
                <tr>
                    <th class="th-tabela text-azul-claro-plataforma-safe">#</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Usuário</th>
                    <th class="th-tabela text-azul-claro-plataforma-safe">Visto há</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of listaClientes; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item.Usuario }}</td>
                    <td>{{ formatTemp(item.Tempo) }} minutos</td>
                </tr>
                </tbody>

            </table>
            <div *ngIf="loadingListaClientes" class="d-flex justify-content-center align-items-center" style="flex: 1;">
                <div class="spinner-border text-azul-plataforma-safe" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>

    </div>
</div>
